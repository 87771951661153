
.policy_block h3 {
    font-size: 18px;
}

.policy_block ul li,
.policy_block ol li {
    padding-bottom: 9px;
}

.policy_block {
    background-color: #fff;
    color: black;
}

.policy_block a {
    color: black;
}

.policy_block  hr {
    border-top: 1px solid rgb(255 255 255 / 48%);
}

